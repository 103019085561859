import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WidgetProps } from "legacy/widgets/BaseWidget/types";
import { fastClone } from "utils/clone";
import {
  FeedbackAction,
  getAiWidgetEditActionsStream,
  sendAiWidgetEditActionsFeedback,
} from "./client";
import hardCodedPromptActions from "./hardCodedPromptActions";
import { DiscardedEdit } from "./processActionsIntoChanges";
import { ComponentEditAction, SortedProperties } from "./types";

const PROCESSING_INTERVAL = 300;

const TEST_ACTIONS: any[] = [
  {
    properties: [
      "padding.top.value",
      "padding.bottom.value",
      "padding.left.value",
      "padding.right.value",
    ],
  },
  { property: "padding.top.value", action: "set", value: 24 },
  { property: "padding.bottom.value", action: "set", value: 24 },
  { property: "padding.left.value", action: "set", value: 24 },
  { property: "padding.right.value", action: "set", value: 24 },
];

const AI_PROMPT_TIMEOUT_DEFAULT = 300;

const PREDEFINED_PROMPT_RESPONSES = [
  {
    rawPrompt:
      "connect the data to creditCardApplications and set the table header based on the data.",
    prompt:
      'connect the data to <api name="creditCardApplications"> and set the table header based on the data',
    actions: hardCodedPromptActions.connectData,
  },
  {
    rawPrompt:
      "Remove the download and search bar. freeze the business name and application timestamp to the left side. Also, add in pills for industry_category and status. Make the status and  format the date in EU format in words and sort by most up to date.",
    prompt:
      "Remove the download and search bar. freeze the business name and application timestamp to the left side. Also, add in pills for industry_category and status. Make the status and  format the date in EU format in words and sort by most up to date",
    actions: hardCodedPromptActions.formatTable,
  },
  // Allow prompt with and without the bad extra space vs. not (space is here: "Make the status and[space][space]format...")
  {
    rawPrompt:
      "Remove the download and search bar. freeze the business name and application timestamp to the left side. Also, add in pills for industry_category and status. Make the status and format the date in EU format in words and sort by most up to date.",
    prompt:
      "Remove the download and search bar. freeze the business name and application timestamp to the left side. Also, add in pills for industry_category and status. Make the status and format the date in EU format in words and sort by most up to date",
    actions: hardCodedPromptActions.formatTable,
  },
  {
    rawPrompt:
      "Add a Flag transaction button in a column, but only enable it if the user is on the RiskOps team.",
    prompt:
      'Add a Flag transaction button in a column, but only enable it if the user is on the <group name="RiskOps"> team',
    actions: hardCodedPromptActions.flagTransaction,
  },
];

const formatPredefinedPrompt = (prompt: string) => {
  return prompt.toLowerCase().trim().replace(/\.$/, "");
};

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const initialState: {
  isLoading: boolean;
  selectedWidgetId?: string;
  initialPosition?: { x: number; y: number };
  changedKeys?: Array<string>;
  dataTreeChanges?: Record<string, unknown>;
  actions?: Array<any>;
  widgetRename?: string;
  error?: string;
  actionsRequestId?: string;
  initialDataTreeChanges?: Record<string, unknown>;
  propertiesToChange?: string[];
  discardedEdits?: DiscardedEdit[];
} = {
  isLoading: false,
};

type GetWidgetEditsPayload = {
  existingWidget: Partial<WidgetProps> | SortedProperties;
  prompt: string;
  rawPrompt: string;
  context: Record<string, any>;
  widgetId: string;
  aiServiceURL: string;
};

export const processAiActions = createAction<{
  actions: ComponentEditAction[];
  existingWidgetId: string;
}>("ai/processAiActions");

export const updateAiChanges = createAction<
  | {
      updates: Record<string, unknown>;
      properties: Record<string, unknown>;
    }
  | {
      rename: string;
    }
>("ai/updateAiChanges");

export const processAiProperties = createAction<{
  properties: string[];
  widgetType: string;
}>("ai/processAiProperties");

export const updateAiDynamicProperties = createAction<{
  itemId: string;
  propertyName: string;
  isDynamicProperty: boolean;
}>("ai/updateAiDynamicProperties");

export const getWidgetEditActionsStream = createAsyncThunk<
  { actions: ComponentEditAction[] },
  GetWidgetEditsPayload
>(
  "ai/getWidgetEditActionsStream",
  async (
    {
      existingWidget,
      prompt,
      rawPrompt,
      context,
      widgetId,
      aiServiceURL,
    }: GetWidgetEditsPayload,
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setIsLoading(true));

    const actions: ComponentEditAction[] = [];
    let batchedActions: ComponentEditAction[] = [];
    let processingInterval: NodeJS.Timeout | null = null;

    const startProcessingInterval = () => {
      processingInterval = setInterval(() => {
        if (batchedActions.length > 0) {
          actions.push(...batchedActions);
          thunkAPI.dispatch(
            processAiActions({
              actions: fastClone(actions),
              existingWidgetId: widgetId,
            }),
          );
          batchedActions = [];
        }
      }, PROCESSING_INTERVAL);
    };

    const onMessage = (message: any) => {
      const event = message;
      if (event.properties) {
        thunkAPI.dispatch(
          processAiProperties({
            properties: event.properties,
            widgetType: existingWidget.type ?? "",
          }),
        );
      } else if (event) {
        if (!processingInterval) {
          startProcessingInterval();
        }
        batchedActions.push(event);
      }
    };

    const matchingPredefinedResponse = PREDEFINED_PROMPT_RESPONSES.find(
      (response) =>
        formatPredefinedPrompt(rawPrompt).includes(
          formatPredefinedPrompt(response.rawPrompt),
        ),
    );

    if (prompt === "test") {
      for (const action of TEST_ACTIONS) {
        onMessage(action);
        await sleep(500);
      }
    } else if (matchingPredefinedResponse) {
      for (const action of matchingPredefinedResponse.actions) {
        const localStorageTimeout = Number(
          localStorage.getItem("ai_prompt_timeout"),
        );
        const timeoutToUse =
          !isNaN(localStorageTimeout) && localStorageTimeout > 0
            ? localStorageTimeout
            : AI_PROMPT_TIMEOUT_DEFAULT;

        await sleep(Math.abs(timeoutToUse));
        onMessage(action);
      }
    } else {
      await getAiWidgetEditActionsStream({
        aiServiceURL,
        existingWidget,
        prompt,
        context,
        signal: thunkAPI.signal,
        onResponse: (response: Response) => {
          // get the header from the response
          // Check if response.headers exists before trying to access it
          const actionsRequestId = response?.headers?.get(
            "x-superblocks-feedback-id",
          );
          if (actionsRequestId) {
            thunkAPI.dispatch(setActionsRequestId(actionsRequestId));
          }
        },
        onError: (error: string, code?: string) => {
          if (code === "MESSAGE_ERROR") {
            thunkAPI.dispatch(
              setError({
                error,
              }),
            );
          } else {
            if (processingInterval) {
              clearInterval(processingInterval);
            }
            thunkAPI.dispatch(
              setError({
                error,
              }),
            );
          }
        },
        onMessage,
      });
    }

    // clean up the interval and process any remaining batched actions
    if (processingInterval) {
      clearInterval(processingInterval);
      if (batchedActions.length > 0) {
        actions.push(...batchedActions);
        thunkAPI.dispatch(
          processAiActions({
            actions: fastClone(actions),
            existingWidgetId: widgetId,
          }),
        );
      }
    }

    thunkAPI.dispatch(setIsLoading(false));
    thunkAPI.dispatch(setActions(actions));

    return {
      actions,
    };
  },
);

export const sendWidgetEditActionsFeedback = createAsyncThunk<
  void,
  FeedbackAction & {
    aiServiceURL: string;
  }
>("ai/sendAiWidgetEditActionsFeedback", async (payload, thunkAPI) => {
  sendAiWidgetEditActionsFeedback(payload);
});

export const aiSlice = createSlice({
  name: "ai",
  initialState,
  reducers: {
    clearAiChanges: (state, action: { payload: { shouldClose: boolean } }) => {
      state.actionsRequestId = undefined;
      state.changedKeys = undefined;
      state.dataTreeChanges = undefined;
      state.initialDataTreeChanges = undefined;
      state.isLoading = false;
      state.actions = undefined;
      state.widgetRename = undefined;
      state.propertiesToChange = undefined;
      state.error = undefined;
      state.discardedEdits = undefined;
      if (action.payload.shouldClose) {
        state.selectedWidgetId = undefined;
        state.initialPosition = undefined;
      }
    },
    setAiChanges: (
      state,
      action: {
        payload: {
          changedKeys: Array<string>;
          dataTreeChanges: Record<string, unknown>;
          rename?: string;
          discardedEdits: DiscardedEdit[];
        };
      },
    ) => {
      const { changedKeys, dataTreeChanges, rename, discardedEdits } =
        action.payload;
      state.changedKeys = changedKeys;
      state.dataTreeChanges = dataTreeChanges;
      state.initialDataTreeChanges = dataTreeChanges;
      state.widgetRename = rename;
      state.discardedEdits = discardedEdits;
    },
    openAiModal: (
      state,
      action: {
        payload: { widgetId: string; position: { x: number; y: number } };
      },
    ) => {
      state.selectedWidgetId = action.payload.widgetId;
      state.initialPosition = action.payload.position;
      // clear all the previous changes
      state.actionsRequestId = undefined;
      state.changedKeys = undefined;
      state.dataTreeChanges = undefined;
      state.initialDataTreeChanges = undefined;
      state.isLoading = false;
      state.actions = undefined;
      state.widgetRename = undefined;
      state.propertiesToChange = undefined;
      state.error = undefined;
      state.actionsRequestId = undefined;
      state.discardedEdits = undefined;
    },
    setIsLoading: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload;
      if (action.payload) {
        state.error = undefined;
      }
    },
    setError: (state, action: { payload: { error: string } }) => {
      state.error = action.payload.error;
    },
    setPropertiesToChange: (state, action: { payload: string[] }) => {
      state.propertiesToChange = action.payload;
    },
    setActions: (state, action: { payload: ComponentEditAction[] }) => {
      state.actions = action.payload;
    },
    setActionsRequestId: (state, action: { payload: string }) => {
      state.actionsRequestId = action.payload;
    },
  },
});

export const {
  clearAiChanges,
  openAiModal,
  setAiChanges,
  setIsLoading,
  setPropertiesToChange,
} = aiSlice.actions;
const { setError, setActions, setActionsRequestId } = aiSlice.actions;
