import { set } from "lodash";
import { call, put, select } from "redux-saga/effects";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { getItemPropertyPaneConfig } from "legacy/pages/Editor/PropertyPane/ItemPropertyPaneConfig";
import { getWidget } from "legacy/selectors/sagaSelectors";
import { mergeUpdatesWithBindingsOrTriggers } from "legacy/utils/DynamicBindingUtils";
import { AllFlags, Flag, selectFlags } from "store/slices/featureFlags";
import { fastClone } from "utils/clone";
import { selectAiState } from "../selectors";
import { updateAiChanges, setAiChanges } from "../slice";
import { applyWidgetHooksToAiEdits } from "./utils";

export function* updateAiChangesSaga(
  action: ReturnType<typeof updateAiChanges>,
) {
  const {
    changedKeys,
    dataTreeChanges,
    widgetRename,
    discardedEdits,
    selectedWidgetId,
  }: ReturnType<typeof selectAiState> = yield select(selectAiState);
  if ("rename" in action.payload) {
    yield put(
      setAiChanges({
        changedKeys: changedKeys || [],
        dataTreeChanges: dataTreeChanges || {},
        rename: action.payload.rename,
        discardedEdits: discardedEdits || [],
      }),
    );
  } else {
    const { updates, properties } = action.payload;
    const featureFlags: Partial<AllFlags> = yield select(selectFlags);
    const changesWithBindings = mergeUpdatesWithBindingsOrTriggers(
      properties,
      getItemPropertyPaneConfig(properties.type as WidgetType),
      updates,
      featureFlags[Flag.ENABLE_DEEP_BINDINGS_PATHS],
    );

    const newDataTreeChanges = dataTreeChanges
      ? fastClone(dataTreeChanges)
      : {};
    const newChangedKeys = changedKeys ? fastClone(changedKeys) : [];
    Object.entries(changesWithBindings).forEach(([key, value]) => {
      set(newDataTreeChanges, key, value);
      if (!newChangedKeys.includes(key)) {
        newChangedKeys.push(key);
      }
    });
    const existingWidget: ReturnType<typeof getWidget> = yield select((state) =>
      getWidget(state, selectedWidgetId ?? ""),
    );
    yield call(applyWidgetHooksToAiEdits, {
      changes: newDataTreeChanges,
      existingWidget,
    });

    yield put(
      setAiChanges({
        changedKeys: newChangedKeys,
        dataTreeChanges: newDataTreeChanges,
        rename: widgetRename,
        discardedEdits: discardedEdits ?? [],
      }),
    );
  }
}
